<template>
  <div class="generic-list scroll-container">
    <CategoryTitle :category="category" />

    <RefundTable />

    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import RefundTable from "@/components/orders/RefundTable";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

export default {
  name: "Refunds",
  data() {
    return {};
  },
  mixins: [reload, categoryMixin],
  components: {
    CategoryTitle,
    RefundTable
  },

  computed: {},
  methods: {
    reload() {}
  },
  created() {
    this.reload();
  }
};
</script>
