<template>
  <div class="refund-table">
    <div v-if="refundList.length > 0">
      <v-row no-gutters class="text-body-2 mt-5 mb-2 headers">
        <v-col cols="4" sm="3">
          {{ $t("refund.refundNumber") }}
        </v-col>
        <v-col cols="4" sm="3">
          {{ $t("refund.date") }}
        </v-col>
        <v-col cols="4" sm="2" class="text-end text-sm-start">
          {{ $t("refund.orderNumber") }}
        </v-col>
        <v-col cols="2" class="hidden-xs-only">
          {{ $t("refund.products") }}
        </v-col>
        <v-col cols="2" class="hidden-xs-only">
          {{ $t("refund.status") }}
        </v-col>
      </v-row>

      <v-list>
        <v-list-item
          v-for="refund in refundList"
          :key="refund.id"
          v-bind:to="'/profile/order-refunds/' + refund.id"
        >
          <v-row no-gutters align="center">
            <v-col cols="4" sm="3">
              <span class="primary--text font-weight-600"
                >#{{ refund.id }}</span
              >
            </v-col>
            <v-col cols="4" sm="3">{{
              $dayjs(refund.timeslotDate).format("DD MMMM YYYY")
            }}</v-col>
            <v-col cols="4" sm="2" class="text-end text-sm-start"
              ><span class="primary--text font-weight-600"
                >#{{ refund.orderId }}</span
              ></v-col
            >
            <v-col cols="2" class="hidden-xs-only">{{ refund.size }}</v-col>
            <v-col cols="2" class="hidden-xs-only">{{
              $t(`refund.refundStatus.${refund.wishlistStatusId}`)
            }}</v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </div>
    <div v-else-if="!loading">
      {{ $t("refund.noRefunds") }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.refund-table {
  .headers {
    font-weight: 600;
  }
  .v-list {
    background-color: transparent;
    .v-list-item {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
<script>
import RefundService from "@/service/refundService";
export default {
  name: "RefundTable",
  props: {
    orderId: { type: Number, required: false }
  },
  data() {
    return {
      loading: true,
      refundList: []
    };
  },
  computed: {},
  methods: {
    loadRefunds() {
      this.loading = true;
      try {
        RefundService.listRefunds(this.orderId).then(data => {
          this.refundList = data.personalList.map(list => {
            return {
              ...list,
              orderId: list.name.replace("Refund-", "")
            };
          });
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    }
  },
  mounted() {
    this.loadRefunds();
  }
};
</script>
